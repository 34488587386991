import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle: "SpotOn Case Study | Fat Zach’s Pizza",
    pageDescription:
      "See how a local pizza joint expanded from a food trailer to three locations with accessible capital, smarter data, and tools to increase profits.",
    title: "A pizza joint’s quick rise",
    subTitle: `How the busy owner-operator of Fat Zach’s Pizza increases profits and saves time with SpotOn.`,
    businessName: "Fat Zach’s Pizza",
    industry: "Restaurant, casual dining",
    location: "Puyallup, Washington",
    products: [
      spotonProducts.RESTAURANT,
      spotonProducts.SIDEKICK,
      spotonProducts.CAPITAL,
      spotonProducts.LOYALTY,
      spotonProducts.ORDER,
    ],
    description:
      "Fat Zach’s Pizza is a community-oriented pizza spot with three locations offering delivery, catering, and pizzas in a family friendly atmosphere. With SpotOn as a partner, owner-operator Zach Johns can access detailed reporting to decrease labor costs, transparent funding through SpotOn Capital, and streamlined online ordering and delivery.",
    quoteData: {
      imgName: "fat-zachs-pizza.png",
      quote: `“My business is my family and SpotOn feels like part of that family. SpotOn is there to help us with technical support, with resources, with SpotOn Capital, and with reports. It’s like they took everything from all the geniuses in the restaurant industry and put it into a POS system.”`,
      personName: "Zach Johns",
      personTitle: "Owner, Fat Zach’s Pizza",
    },
    goal: `When Johns had the opportunity to scale quickly, he needed tools to increase profits and a trusted business partner to provide resources and technical support. He also needed in-depth reporting, accessible from anywhere, to save Johns and his managers precious time.`,
    solution: `With a Capital loan, Johns could quickly access funding to pay for equipment and upgrades and pay it back from a percentage of his daily sales. He can reach more customers with SpotOn Delivery, which routes orders directly to his POS instead of multiple third-party tablets. Intuitive sales and labor reports help Johns make smart staffing decisions and keep labor costs down.`,
    solutionReadMore: {
        text: `Read more about their story.`,
        url: `http://www.spoton.com/blog/must-love-pizza-fat-zachs/`,
        },
    results: {
      title: `The results`,
      stats: [
        {   title: `1-3 hours`, 
            description: `saved on weekly admin` },
        {
            title: `20%`,
            description: `decrease in labor cost`,
        },
        { 
            title: `$100k`, 
            description: `savings in yearly credit card processing fees` 
        },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
        title: "Hot pizza after cold ski days",
        imageName: "base-camp-pizza.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-05-09",
        href: "/case-studies/base-camp-pizza",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.CONNECT_AND_SELL_ONLINE,
        ],
    },
    {
        title: "A bigger slice of the pie",
        imageName: "bella-pizza.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-05-05",
        href: "/case-studies/bella-pizza",
        goals: [
          resourceGoals.CONNECT_AND_SELL_ONLINE,
          resourceGoals.GET_SMARTER_DATA,
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
        ],
    },
    {
        title: "Financing chef-driven concepts",
        imageName: "bardo-and-vana.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-07-07",
        href: "/case-studies/bardo-and-vana",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
  ];
  